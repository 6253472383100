.App {
  text-align: center;
  min-height: 100vh;
}

/* Navbar */
.navbar-container {
  border-bottom: 2px solid black;
}

a.nav-link {
  color: black;
  font-size: 18px;
}

a.nav-link:hover {
  color: #E7AC5A;
}

/* Styles for active link  */
a.nav-link.active {
  text-decoration: none;
}

/* Underline under active link */
a.nav-link.active::after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #E7AC5A;
  margin-top: 2px;
}

.navbar-text svg:hover {
  color: #E7AC5A !important;
}

/* Scroll to top buton */
.scroll-to-top {
  position: fixed;
  bottom: 55px;
  right: 7px;
  cursor: pointer;
  background-color: transparent;
  z-index: 999;
}

.scroll-to-top:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

/* Sevices - card */
.container-wrapper {
  width: 85%;
  margin: 0 auto; /* Centering the container on the page */
}

.card-container {
  flex: 1 0 33%;
  max-width: 30%;
  padding: 0px 12px;
}

.custom-style-img {
  cursor: pointer;
}

.custom-style-img:hover {
  opacity: 0.9;
}

/* Image Gallery */
.gallery.custom-style-img {
  opacity: 0;
  transition: scale(0.95);
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
  cursor: pointer;
}

.gallery.custom-style-img.loaded {
  opacity: 1;
  transform: scale(1);
}

.gallery.custom-style-img:hover {
  opacity: 0.9;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1; /* The main content takes up the remaining space. */
}

/* Form contact */
input.form-control,
textarea.form-control,
select.form-select {
  background-color: #EDD0A7;
  border-color: #EDD0A7 ;
}

input.form-control:focus,
textarea.form-control:focus,
select.form-select:focus {
  background-color: #EDD0A7;
  border-color: #5D4A80;
  border-width: 2px;  
}

/* Footer */
.footer {
  width: 100%;
  background-color: black;
  color: white;
}

.section-container {
  margin-left: 48px;
  margin-right: 48px;
}

.footer .container {
  width: 100%;
  max-width: none;
  margin-right: auto;
  margin-left: auto;
  padding-right: 48px;
  padding-left: 48px;
}

.footer a {
  color: white;
  text-decoration: none;
  font-size: 16px;
}

.footer .mail {
  cursor: pointer;
}

/* PhotoOverlay */
.photo-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 7px;
  background: none;
  border: grey;
  border-width: 2px;
  color: #ced4da;
  font-size: 42px;
  font-weight: bold;
  cursor: pointer;
  opacity: 1;
}

.photo-overlay figcaption {
  margin-bottom: 50px !important;
}

/* PrivacyPolicy */
.content {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.content.loaded {
  opacity: 1;
}

.privacy-policy-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.privacy-policy-wrapper .content {
  flex:1;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 100px;
}

@media (max-width: 1499px) {
  .container-wrapper {
    width: 95%;
    margin: 0 auto;
  }
}

@media (max-width: 1024px) {
  .navbar > .container-fluid {
    margin: 0 22px !important;
  }

  #section-1.section-container {
    margin-top: 86px !important;
  }

  #section-1 img {
    max-width: 450px !important;
  }

  .card-container {
    flex-basis: 45%;
    max-width: 45%;
  }

  .gallery.section-container {
    margin-top: 86px !important;
  }

  .section-container {
    margin-left: 22px !important;
    margin-right: 22px !important;
  }

  .footer .container {
    padding-left: 22px !important;
    padding-right: 22px !important;
  }
}

@media (max-width: 1023px) {
  .navbar > .container-fluid {
    margin: 0 22px !important;
  }

  a.active.nav-link::after {
    content: '';
    display: block;
    width: 25%;
    height: 1px;
    background-color: #E7AC5A;
    margin: 0 auto;
    margin-top: 2px;
  }

  .scroll-to-top {
    right: 22px !important;
  }

  #section-1.section-container {
    margin-top: 86px !important;
  }

  #section-1 img {
    max-width: 450px !important;
  }

  .gallery.section-container {
    margin-top: 86px !important;
  }

  .section-container {
    margin-left: 22px !important;
    margin-right: 22px !important;
  }

  .card-container {
    flex-basis: 45%;
    max-width: 45%;
  }

  #section-3 form, 
  #section-3 p {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .photo-overlay.close-button {
    right: 7px !important; 
  }

  .footer .container {
    padding-left: 22px !important;
    padding-right: 22px !important;
  }
}

@media (max-width: 767px) {
  .navbar > .container-fluid {
    margin: 0 12px !important;
  }

  a.active.nav-link::after {
    content: '';
    display: block;
    width: 50%;
    height: 1px;
    background-color: #E7AC5A;
    margin: 0 auto;
    margin-top: 2px;
  }

  #section-1.section-container {
    margin-top: 86px !important;
  }

  .gallery.section-container {
    margin-top: 86px !important;
  }

  .section-container {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }

  .scroll-to-top {
    display: none;
  }  

  .card-container {
    flex-basis: 100%;
    max-width: 100%;
    padding-bottom: 35px;
  }

  #section-3 form, 
  #section-3 p {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .photo-overlay.close-button {
    right: 7px !important; 
  }
}

