@font-face {
    font-family: 'Montserrat-Bold';
    src: url('./fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'Montserrat-Light';
    src: url('./fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
    font-weight: light;
}

@font-face {
    font-family: 'Montserrat-Medium';
    src: url('./fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
    font-weight: medium;
}

@font-face {
    font-family: 'Montserrat-Regular';
    src: url('./fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url('./fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
}